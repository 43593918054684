body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@keyframes glowing {
  0% {
    box-shadow: 0 0 5px #ffcb0f;
  }
  50% {
    box-shadow: 0 0 22px #ffcb0f;
  }
  100% {
    box-shadow: 0 0 5px #ffcb0f;
  }
}
.shadow {
  animation: glowing 3000ms infinite;
}
@media screen and (max-width: 544px) {
  .MuiDialog-paperScrollPaper {
    width: 100%;
  }
}
